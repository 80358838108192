import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['adBlock', 'adPlaceHolder']
  }

  static get values() {
    return {
      source: String,
      size: Array,
    }
  }

  connect() {
    const googletag = window.googletag
    googletag.cmd.push(() => {
      googletag
        .defineSlot(this.sourceValue, this.sizeValue, this.adBlockTarget.id)
        .addService(googletag.pubads())
      googletag.pubads()
      googletag.enableServices()
      googletag
        .pubads()
        .addEventListener(
          'slotRenderEnded',
          this.showPlaceHoderIfEmptySlot.bind(this)
        )
      googletag.display(this.adBlockTarget.id)
      googletag
        .pubads()
        .removeEventListener(
          'slotRenderEnded',
          this.showPlaceHoderIfEmptySlot.bind(this)
        )
    })
  }

  disconnect() {
    window.googletag.destroySlots()
  }

  showPlaceHoderIfEmptySlot(e) {
    if (e.slot.getSlotElementId() === this.adBlockTarget.id) {
      if (e.isEmpty) {
        this.adPlaceHolderTarget.classList.remove('hidden')
      }
    }
  }
}
