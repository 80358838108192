import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['fixedArea', 'commentForm']
  }

  onScroll() {
    const body = document.body
    const html = document.documentElement
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    const footerHeight = document.getElementById('footer').offsetHeight

    if (window.pageYOffset >= height - window.innerHeight - footerHeight) {
      this.disableFixed()
    } else {
      this.enableFixed()
    }
  }

  enableFixed() {
    if (this.fixedAreaTarget.classList.contains('fixed')) return

    this.fixedAreaTarget.classList.remove('absolute')
    this.fixedAreaTarget.classList.add('fixed', 'pr-6')
    this.commentFormTarget.classList.add('w-2/3')
  }

  disableFixed() {
    if (this.commentFormTarget.classList.contains('absolute')) return

    this.fixedAreaTarget.classList.add('absolute')
    this.fixedAreaTarget.classList.remove('fixed', 'pr-6')
    this.commentFormTarget.classList.remove('w-2/3')
  }
}
