import { Controller } from 'stimulus'
import '../home4U_select'

export default class extends Controller {
  static get values() {
    return {
      gtmId: String,
    }
  }

  connect() {
    this.build_gtm_url(
      window,
      document,
      'script',
      'dataLayerHome4U',
      this.gtmIdValue
    )

    window.redirect_with_home4u_datalayer = function (url, gaLabel) {
      window.dataLayerHome4U.push({
        event: 'Home4U',
        label: gaLabel,
      })
      window.location = url
    }
  }

  build_gtm_url(window, document, createTag, gtmDatalayer, gtmId) {
    window[gtmDatalayer] = window[gtmDatalayer] || []
    window[gtmDatalayer].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const firstScript = document.getElementsByTagName(createTag)[0]
    const newScript = document.createElement(createTag)
    const params = gtmDatalayer !== 'dataLayer' ? '&l=' + gtmDatalayer : ''

    newScript.async = true
    newScript.src =
      'https://www.googletagmanager.com/gtm.js?id=' + gtmId + params
    firstScript.parentNode.insertBefore(newScript, firstScript)
  }
}
