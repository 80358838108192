import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['toggleable', 'showbackground', 'hamburger']
  }

  toggle() {
    const body = document.querySelector('body')
    this.toggleableTarget.style.display = 'block'

    if (this.toggleableTarget.classList.contains('slide-in')) {
      body.style.removeProperty('overflow-y')
      body.style.removeProperty('width')
      this.burgerClose()
      this.slideOut()
    } else {
      body.style.setProperty('overflow-y', 'hidden', 'important')
      body.style.setProperty('width', '100vw', 'important')
      this.burgerOpen()
      this.slideIn()
    }
  }

  slideIn() {
    this.toggleableTarget.style.setProperty('overflow-y', 'auto')
    this.toggleableTarget.style.setProperty('height', '-webkit-fill-available')
    this.toggleableTarget.classList.remove('slide-out')
    this.toggleableTarget.classList.add('slide-in')
    this.showbackgroundTarget.classList.remove('hidden')
  }

  slideOut() {
    this.toggleableTarget.classList.remove('slide-in')
    this.toggleableTarget.classList.add('slide-out')
    this.showbackgroundTarget.classList.add('hidden')
  }

  burgerOpen() {
    this.hamburgerTarget.classList.add('menu-btn-burger-open')
  }

  burgerClose() {
    this.hamburgerTarget.classList.remove('menu-btn-burger-open')
  }
}
