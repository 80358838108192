import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content']
  }

  connect() {
    this.addTableWraps()
  }

  addTableWraps() {
    const tables = this.contentTarget.querySelectorAll('table')

    if (tables.length === 0) return

    tables.forEach((table) => {
      const wrap = document.createElement('div')
      wrap.setAttribute('class', 'overflow-scroll')
      table.parentNode.insertBefore(wrap, table.nextSibling)
      wrap.appendChild(table)
    })
  }
}
