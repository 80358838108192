import '@hotwired/turbo-rails'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-polyfill'
import 'element-remove'
import 'classlist-polyfill'
import 'whatwg-fetch'
import '@stimulus/polyfills'
import '../shared/jquery'
import '../frontend/flexslider'
import '../shared/google/google_tag_manage'

import Alpine from 'alpinejs/dist/module.cjs'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import FlashController from '../shared/controllers/flash_controller'

const stimulus = Application.start()
stimulus.register('flash', FlashController)
const controllers = require.context(
  '../frontend/controllers_v2/',
  true,
  /\.js$/
)
stimulus.load(definitionsFromContext(controllers))

window.Alpine = Alpine
Alpine.start()

require.context('../images', true)
require.context('../stylesheets/frontend_v2', true)
