import moment from 'moment'
import RangeInputController from '../../shared/controllers/range_input_controller'

export default class extends RangeInputController {
  static get targets() {
    return ['publishedTimeFrom', 'publishedTimeTo']
  }

  restrictPublishedTimeInPreviousMonth() {
    const from = moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')
    const to = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    this.publishedTimeFromTarget.value = from
    this.publishedTimeToTarget.value = to
  }

  restrictPublishedTimeInThisMonth() {
    const from = moment().startOf('month').format('YYYY-MM-DD')
    const to = moment().endOf('month').format('YYYY-MM-DD')
    this.publishedTimeFromTarget.value = from
    this.publishedTimeToTarget.value = to
  }
}
