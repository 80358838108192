module.exports = {
  content: [
    './app/views/**/*.slim',
    './app/views/**/*.erb',
    './app/values/category_color.rb',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/javascript/range_slider_v2/*.js',
    './config/initializers/simple_form_bootstrap.rb',
    './config/view_data/social_shared.yml',
    './app/components/**/*.rb',
    './app/components/**/*.erb',
  ],
  theme: {
    screens: {
      md: '768px',
      lg: '992px',
      xl: '1100px',
    },
    container: {
      center: true,
    },
    extend: {
      spacing: {
        116: '29rem',
      },
    },
  },
  corePlugins: {
    container: true,
  },
  plugins: [
    require('./plugins/fudousan'),
    require('./plugins/gradients'),
    require('@tailwindcss/aspect-ratio'),
  ],
}
