import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['menu']
  }

  connect() {
    const links = this.menuTarget.querySelectorAll('a')
    links.forEach((link) => {
      link.addEventListener('mousedown', (e) => {
        e.preventDefault()
      })
    })
  }
}
