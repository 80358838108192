const plugin = require('tailwindcss/plugin')
const colors = require('tailwindcss/colors')

module.exports = plugin(function ({ _addUtilities, _variants, _theme }) {}, {
  theme: {
    extend: {
      colors: {
        transparent: 'transparent',
        current: 'currentColor',

        black: colors.black,
        white: colors.white,
        gray: colors.slate,
        red: colors.red,
        yellow: colors.amber,
        green: colors.emerald,
        blue: colors.blue,
        indigo: colors.indigo,
        purple: colors.violet,
        pink: colors.pink,

        primary: {
          100: '#F2F3F9',
          200: '#E4E6F3',
          210: '#E2E8F0',
          300: '#BFC3DF',
          400: '#A9AED9',
          410: '#90CDF4',
          500: '#8284BB',
          600: '#5B67AE',
          610: '#6187BF',
          700: '#4653A2',
          800: '#262A85',
          900: '#101468',
        },
        secondary: {
          100: '#DDFAFF',
          200: '#BAF2FC',
          210: '#BEE3F8',
          300: '#88E4F4',
          400: '#58D6EC',
          410: '#9AE6B4',
          500: '#2CB6CE',
          600: '#009CB7',
          610: '#14A0A9',
          700: '#09889E',
          800: '#0C6D7E',
          900: '#025362',
        },
        accent: {
          600: '#E66468',
          610: '#DC7C3A',
        },
        minkabu: '#E60012',
        facebook: '#1877F2',
        twitter: '#1DA1F2',
        mask: 'rgba(74,85,104,.8)',
        smoke: 'rgba(113, 128, 150, 0.8)',
        bluegrey: '#5F717E',
        neutral: {
          500: '#737373',
        },
        sky: {
          900: '#0c4a6e',
        },
        grayslate: {
          300: '#CBD5E1',
          600: '#475569',
          700: '#334155',
        },
      },
      borderColor: {
        F1F9F5: '#F1F9F5',
      },
      fill: (theme) => ({
        minkabu: theme('colors.minkabu'),
        white: theme('colors.white'),
      }),
      fontFamily: {
        corporate: ['corporate-logo-ver2', 'sans-serif'],
        sans: [
          '-apple-system',
          'BlinkMacSystemFont',
          'Roboto',
          'Segoe UI',
          'Helvetica',
          'Arial',
          'Noto Sans JP',
          'ヒラギノ角ゴ ProN',
          'Hiragino Kaku Gothic ProN',
          'YuGothic',
          '游ゴシック',
          'Meiryo',
          'メイリオ',
          'MS PGothic',
          'ＭＳ Ｐゴシック',
          'sans-serif',
        ],
      },
      fontSize: {
        0: '0',
        '2xs': '0.625rem',
      },
      gradients: {
        primary: {
          200: ['#E4E6F3', '#E2E8F0'],
          400: ['#A9AED9', '#90CDF4'],
          600: ['#4653A2', '#6187BF'],
        },
        secondary: {
          200: ['#BAF2FC', '#BEE3F8'],
          400: ['#58D6EC', '#9AE6B4'],
          600: ['#0C6D7E', '#14A0A9'],
        },
        accent: {
          200: ['#FFD6D7', '#FFDEC8'],
          400: ['#FF8F93', '#F6A065'],
          600: ['#E66468', '#DC7C3A'],
        },
        mask: {
          default: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0.4)'],
        },
      },
      lineHeight: {
        3.5: '0.875rem',
        4.5: '1.125rem',
        5.5: '1.375rem',
        11: '2.75rem',
        12: '3rem',
        32: '8rem',
        48: '12rem',
      },
      spacing: {
        4.5: '1.125rem',
        7: '1.75rem',
        14: '3.5rem',
        15: '3.875rem', //62px
        18: '4.5rem',
        30: '7.5rem',
        31: '7.75rem',
        48: '12rem',
        62: '16.5rem',
        72: '18rem',
        74: '18.5rem',
        75: '18.75rem',
        88: '22rem',
        125: '31.25rem',
        162: '40.5rem',
        '15px': '15px',
      },
      maxWidth: {
        448: '28rem',
      },
      minWidth: {
        24: '6rem',
        74: '18.5rem',
        992: '992px',
      },
      minHeight: {
        20: '5rem',
        62: '15.5rem',
      },
      width: {
        7.5: '1.875rem',
        54: '13.5rem',
        21: '5.25rem',
        74: '18.5rem',
        88: '22rem',
        124: '31rem',
        750: '46.875rem', // 750px
        838: '68.875rem',
        336: '21rem',
        'w-min': 'min-content',
      },
      height: {
        7.5: '1.875rem',
        19: '4.75rem',
        62: '15.5rem',
        422: '26.375rem',
        135: '8.438rem',
        197: '12.313rem',
        162: '10.125rem',
      },
      inset: {
        34: '8.5rem',
        35: '8.75rem',
        83.75: '20.9375rem',
        '1/2': '50%',
      },
      borderWidth: {
        3: '3px',
      },
      gridTemplateColumns: {
        '6-auto': 'repeat(6, minmax(0, auto))',
        '5-auto': 'repeat(5, minmax(0, auto))',
        '4-auto': 'repeat(4, minmax(0, auto))',
        '3-auto': 'repeat(3, minmax(0, auto))',
        'left-fixed': 'var(--left-w) auto',
        min: 'repeat(5, min-content)',
      },
      gridTemplateRows: {
        '6-auto': 'repeat(6, minmax(0, auto))',
        '5-auto': 'repeat(5, minmax(0, auto))',
        '4-auto': 'repeat(4, minmax(0, auto))',
        '3-auto': 'repeat(3, minmax(0, auto))',
      },
      margin: {
        59: '3.6875rem',
      },
      zIndex: {
        '-50': '-50',
        999999: '999999',
      },
    },
  },
})
