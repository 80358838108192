// source: https://common.home4u.jp/common/js/select_area_sell.js

function submitHome4uAreaForm(obj, behaviorActionCode) {
  const formId = obj.id

  jQuery('#' + formId + ' [name="prefecture_not_selected_error"]').hide()
  jQuery('#' + formId + ' [name="city_not_selected_error"]').hide()

  if (
    jQuery('#' + formId + ' [name="home4u_prefecture"]').val() !== 0 &&
    jQuery('#' + formId + ' [name="home4u_city"]').val() !== 0
  ) {
    const prefecture = jQuery(
      '#' + formId + ' [name="home4u_prefecture"]'
    ).val()
    const city = jQuery('#' + formId + ' [name="home4u_city"]').val()
    let url =
      'https://www.home4u.jp/sell/assess/assess3.php?sv=02&a2=' +
      prefecture +
      '&a3=' +
      city
    if (jQuery('#' + formId + ' [name="al"]').length > 0) {
      const al = jQuery('#' + formId + ' [name="al"]').val()
      if (al !== '') url = url + '&al=' + al
    }
    if (jQuery('#' + formId + ' [name="ad"]').length > 0) {
      const ad = jQuery('#' + formId + ' [name="ad"]').val()
      if (ad !== '') url = url + '&ad=' + ad
    }
    if (jQuery('#' + formId + ' [name="xadid"]').length > 0) {
      const xadid = jQuery('#' + formId + ' [name="xadid"]').val()
      if (xadid !== '') url = url + '&xadid=' + xadid
    }
    if (behaviorActionCode !== undefined && behaviorActionCode !== '') {
      url = url + '&bhac=' + behaviorActionCode
    }
    // location.href=url
    window.redirect_with_home4u_datalayer(url, obj.dataset.gaLabel)
  } else if (jQuery('#' + formId + ' [name="home4u_prefecture"]').val() === 0) {
    jQuery('#' + formId + ' [name="prefecture_not_selected_error"]').show()
  } else if (
    jQuery('#' + formId + ' [name="home4u_prefecture"]').val() > 0 &&
    jQuery('#' + formId + ' [name="home4u_city"]').val() === 0
  ) {
    if (
      jQuery('#' + formId + ' [name="unsupported_area_error"]').css(
        'display'
      ) !== 'block'
    ) {
      jQuery('#' + formId + ' [name="city_not_selected_error"]').show()
    }
  }
  return false
}

function setHome4uCityOptions(obj) {
  const formId = obj.form.id

  jQuery('#' + formId + ' [name="prefecture_not_selected_error"]').hide()
  jQuery('#' + formId + ' [name="city_not_selected_error"]').hide()
  jQuery('#' + formId + ' [name="unsupported_area_error"]').hide()
  jQuery('#' + formId + ' [name="home4u_city"]').removeAttr('disabled')

  const user = jQuery('#' + formId + ' [name="user"]').val()
  const area = jQuery('#' + formId + ' [name="area"]').val()
  const prefecture = jQuery('#' + formId + ' [name="home4u_prefecture"]').val()
  const version = jQuery('#' + formId + ' [name="version"]').val()

  jQuery('#' + formId + ' [name="home4u_city"]').empty()
  const $options = []
  $options.push('<option value="0">市区町村を選択してください</option>')

  const currentUrl = location.href
  let protocol = 'http://'
  if (currentUrl.match(/^https:/)) {
    protocol = 'https://'
  }

  let apiUrl
  apiUrl = protocol + 'common.home4u.jp/api/areainfo.php'
  apiUrl = apiUrl + '?user=' + user
  apiUrl = apiUrl + '&area=' + area
  apiUrl = apiUrl + '&prefecture=' + prefecture
  apiUrl = apiUrl + '&version=' + version
  apiUrl = apiUrl + '&callback=?'

  jQuery
    .getJSON(apiUrl, (data) => {
      for (const key in data) {
        if (key === 'info') {
          for (const key2 in data[key]) {
            if (key2 === 'code' && data[key][key2] === '401') {
              jQuery('#' + formId + ' [name="unsupported_area_error"]').show()
              jQuery('#' + formId + ' [name="home4u_city"]').attr(
                'disabled',
                'disabled'
              )
            }
          }
          break
        }
        $options.push('<option value="' + key + '" >' + data[key] + '</option>')
      }
      jQuery('#' + formId + ' [name="home4u_city"]').append($options.join(''))
      jQuery('#' + formId + ' [name="home4u_city"]').focus()
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      jQuery('#' + formId + ' [name="home4u_city"]').append($options.join(''))
      jQuery('#' + formId + ' [name="home4u_city"]').focus()
    })
}

window.submitHome4uAreaForm = submitHome4uAreaForm
window.setHome4uCityOptions = setHome4uCityOptions
