import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['searchResult']
  }

  connect() {
    if (this.searchResultTarget != null) {
      document.addEventListener('scroll', () => {
        this.scrollHandler()
      })
    }
  }

  scrollHandler() {
    const footerHeight = document.querySelector('footer').clientHeight
    const bodyHeight = document.body.clientHeight
    const windowHeight = window.innerHeight
    const scrollUnderFooter = bodyHeight - windowHeight - footerHeight

    if (window.scrollY > scrollUnderFooter) {
      const bottomNum = window.scrollY - scrollUnderFooter
      this.searchResultTarget.style.bottom = `${bottomNum}px`
    } else {
      this.searchResultTarget.style.bottom = '0px'
    }
  }
}
