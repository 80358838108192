import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content', 'switcher']
  }

  static get values() {
    return {
      contentHeight: Number,
    }
  }

  drop_down() {
    this.switcherTarget.classList.toggle('rotate')
    if (!this.contentTarget.style['--height']) {
      this.contentTarget.style.setProperty(
        '--height',
        this.contentHeightValue + 'px'
      )
    }
    this.contentTarget.classList.toggle('slide-down-to')
  }
}
