import 'flexslider/jquery.flexslider'
import 'flexslider/flexslider.css'

$(document).on('turbo:load', function () {
  $('.flexslider').flexslider({
    animation: 'fade',
    controlsContainer: '.flexslider',
    controlNav: false,
  })

  if ($('ul.slides > li').length < 2) {
    $(this).find('.flex-direction-nav').remove()
  }
})
