import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'

export default class extends Controller {
  static get targets() {
    return []
  }

  availableKeys() {
    return [
      'dateFormat',
      'defaultDate',
      'defaultHour',
      'defaultMinute',
      'disable',
      'disableMobile',
      'enable',
      'enableTime',
      'enableSeconds',
      'hourIncrement',
      'maxDate',
      'minDate',
      'minuteIncrement',
      'mode',
      'noCalendar',
      'time_24hr',
      'weekNumbers',
    ]
  }

  connect() {
    this.initFlatPickr()
  }

  initFlatPickr() {
    flatpickr(this.element, this.options())
  }

  options() {
    const data = this.element.dataset
    const config = {}

    Object.keys(data).forEach((key) => {
      if (this.isAvailableKeys(key)) {
        config[key] = this.parseType(data[key])
      }
    })

    return { ...config, ...this.baseOptions() }
  }

  baseOptions() {
    return { locale: { ...Japanese, firstDayOfWeek: 0 } }
  }

  parseType(data) {
    if (data === 'true') {
      return true
    }
    if (data === 'false') {
      return false
    }
    if (data === 'null') {
      return null
    }
    if (data === +data + '') {
      return +data
    }

    return data
  }

  isAvailableKeys(key) {
    this.availableKeys().includes(key)
  }
}
