import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['notApplicableButton', 'ApplicableButton']
  }

  static get values() {
    return {
      id: String,
    }
  }

  async connect() {
    const formNotApplied = await this.checkSeminarApplicable()
    if (formNotApplied === true) {
      this.ApplicableButtonTarget.classList.remove('hidden')
    } else {
      this.notApplicableButtonTarget.classList.remove('hidden')
    }
  }

  async checkSeminarApplicable() {
    try {
      const response = await fetch(
        `/api/v2/seminars/${this.idValue}/applicable`,
        {
          method: 'GET',
        }
      )
      const result = await response.json()
      return result
    } catch (e) {
      console.error(e)
    }
  }
}
