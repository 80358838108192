var _flattenColorPalette = require('tailwindcss/lib/util/flattenColorPalette')

module.exports = function ({ addUtilities, e, theme, variants }) {
  const gradients = _flattenColorPalette.default(theme('gradients'))
  const gradientVariants = variants('gradients', [])
  const utilities = Object.entries(gradients).map(
    ([modifier, [start, end]]) => ({
      [`.bg-gradient-${modifier}-r`]: {
        backgroundImage: `linear-gradient(to right, ${start}, ${end})`,
      },
      [`.bg-gradient-${modifier}-l`]: {
        backgroundImage: `linear-gradient(to left, ${start}, ${end})`,
      },
      [`.bg-gradient-${modifier}-t`]: {
        backgroundImage: `linear-gradient(to top, ${start}, ${end})`,
      },
      [`.bg-gradient-${modifier}-b`]: {
        backgroundImage: `linear-gradient(to bottom, ${start}, ${end})`,
      },
    })
  )

  addUtilities(utilities, gradientVariants)
}
