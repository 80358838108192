import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['notInquiryButton', 'alreadyInquiryButton']
  }

  static get values() {
    return {
      id: String,
    }
  }

  async connect() {
    const formNotCompleted = await this.checkPropertyInquiry()
    if (formNotCompleted === false) {
      this.alreadyInquiryButtonTarget.classList.remove('hidden')
    } else {
      this.notInquiryButtonTarget.classList.remove('hidden')
    }
  }

  async checkPropertyInquiry() {
    try {
      const response = await fetch(
        `/api/v2/properties/${this.idValue}/inquiries/status`,
        {
          method: 'GET',
        }
      )
      const result = await response.json()
      return result
    } catch (e) {
      console.error(e)
    }
  }
}
