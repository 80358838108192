import InquiryCommentController from '../../shared/controllers/inquiry_comment_controller'

export default class extends InquiryCommentController {
  displayFiles(element, files) {
    Array.from(files).forEach((file) => {
      this.fileLinkTarget.setAttribute('href', file.file_url)
      const iconClasses = file['image?']
        ? ['far', 'fa-image']
        : ['fas', 'fa-file']
      this.fileIconTarget.classList.add(...iconClasses)

      const fileTemplate = this.fileTemplateTarget.lastChild.cloneNode(true)
      this.fileIconTarget.classList.remove('fa-file', 'fa-image')
      const newTextNode = document.createTextNode(file.filename)
      fileTemplate.appendChild(newTextNode)
      element.appendChild(fileTemplate)
      this.cleanDataTarget(element)
    })
    this.fileContainerTarget.innerHTML = ''
  }

  displaySenderAvatar(element, avatarSource) {
    const avatarIconOption = ['fa-building', 'fa-user']
    let senderAvatarElement, avatarClasses

    if (avatarIconOption.includes(avatarSource)) {
      senderAvatarElement = document.createElement('i')

      if (avatarSource === 'fa-building') {
        avatarClasses = ['text-gray-500', 'fas', 'fa-building']
        element.classList.add('bg-white')
      } else if (avatarSource === 'fa-user') {
        avatarClasses = ['text-white', 'fas', 'fa-user']
        element.classList.add('bg-gray-500')
      }
    } else {
      avatarClasses = ['i-amphtml-fill-content', 'i-amphtml-replaced-content']
      senderAvatarElement = document.createElement('img')
      senderAvatarElement.src = avatarSource
    }
    senderAvatarElement.classList.add(...avatarClasses)
    element.appendChild(senderAvatarElement)
    this.cleanDataTarget(element)
  }
}
