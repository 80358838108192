import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['scrollToTopButton']
  }

  connect() {
    if (screen.width < 768) {
      document.addEventListener('scroll', () => {
        this.displayScrollToTopButton()
      })

      this.lastScroll = 0
      document.addEventListener('scroll', () => {
        this.determineScrollDirection()
      })
    }
  }

  toTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  displayScrollToTopButton() {
    if (window.scrollY > 5) {
      this.scrollToTopButtonTarget.classList.remove('hidden')
    } else {
      this.scrollToTopButtonTarget.classList.add('hidden')
    }
  }

  determineScrollDirection() {
    const body = document.body
    const currentScroll = window.pageYOffset

    if (currentScroll <= 0) {
      body.classList.remove('scroll-up')
    }
    if (
      currentScroll > this.lastScroll &&
      !body.classList.contains('scroll-down')
    ) {
      body.classList.remove('scroll-up')
      body.classList.add('scroll-down')
    } else if (
      currentScroll < this.lastScroll &&
      body.classList.contains('scroll-down')
    ) {
      body.classList.remove('scroll-down')
      body.classList.add('scroll-up')
    }
    this.lastScroll = currentScroll
  }
}
